<template>
	<div class="site-wrapper" :class="{ 'site-sidebar--fold': sidebarFold }" element-loading-text="玩命加载中...">
		<!-- header 头部  -->
		<nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
			<div class="site-navbar__header">
				<h1 class="site-navbar__brand">
					<a class="site-navbar__brand-lg">GRC珠宝鉴定管理系统</a>
					<a class="site-navbar__brand-mini">GRC</a>
				</h1>
			</div>
			<div class="site-navbar__body clearfix">
				<el-menu class="site-navbar__menu" mode="horizontal">
					<el-menu-item class="site-navbar__switch" index="0" @click="handleSwitch">
						<SvgIcon name="zhedie" class="icon-svg" />
					</el-menu-item>
				</el-menu>
				<el-menu class="site-navbar__menu site-navbar__menu--right" mode="horizontal">
					<el-menu-item index="1" class="site-navbar__switch">
						<template #title>
							<el-badge value="0">
								<SvgIcon name="duanxin" class="icon-svg duanxin-svg" />
							</el-badge>
						</template>
					</el-menu-item>
					<el-menu-item>
						<span>{{ deptName }}</span>
					</el-menu-item>
					<el-menu-item class="site-navbar__avatar" index="3">
						<el-dropdown>
							<span class="el-dropdown-link">
								<img :src="avatar" />
								{{ realName }}
							</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item @click.native="modifyPasswordHandle()">修改密码</el-dropdown-item>
									<el-dropdown-item @click.native="logout()">退出</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</el-menu-item>
				</el-menu>
			</div>
		</nav>
		<!-- 左侧菜单 -->
		<aside class="site-sidebar site-sidebar--dark">
			<div class="site-sidebar__inner">
				<el-menu :default-active="menuActiveName || 'home'" :collapse="sidebarFold" :collapseTransition="false"
					class="site-sidebar__menu" router>
					<el-menu-item index="main" @click.prevent="$router.push({ name: 'Main' })">
						<SvgIcon name="home" class="icon-svg" />
						<span slot="title">首页</span>
					</el-menu-item>
					<el-submenu v-for="menu in this.menus" :index="menu.name" :key="menu.id"
						:popper-class="'site-sidebar--' + sidebarLayoutSkin + '-popper'">
						<template #title>
							<SvgIcon name="users_fill" class="icon-svg" />
							<span slot="title">{{ menu.name }}</span>
						</template>
						<el-menu-item v-for="child in menu.children" :index="child.vueUrl" :key="child.id" v-if="true">
							<SvgIcon name="company_fill" class="icon-svg" />
							<span slot="title">{{ child.name }}</span>
						</el-menu-item>
					</el-submenu>
				</el-menu>
			</div>
		</aside>
		<!-- 内容区域 -->
		<div class="site-content__wrapper" :style="{ 'min-height': documentClientHeight + 'px' }">
			<main class="site-content" :class="{ 'site-content--tabs': $route.meta.isTab }" v-loading="loading"
				element-loading-text="玩命加载中... ...">
				<el-tabs v-if="$route.meta.isTab" v-model="mainTabsActiveName" :closable="true"
					@tab-click="selectedTabHandle"
					@tab-remove="removeTabHandle">
					<!-- @tab-click="selectedTabHandle"  -->
					<el-dropdown class="site-tabs__tools" trigger="click">
						<i class="el-icon-arrow-down el-icon--right"></i>
						<template #dropdown>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="tabsCloseCurrentHandle">关闭当前标签页</el-dropdown-item>
								<el-dropdown-item @click.native="tabsCloseOtherHandle">关闭其它标签页</el-dropdown-item>
								<el-dropdown-item @click.native="tabsCloseAllHandle">关闭全部标签页</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
					<el-tab-pane v-for="item in mainTabs" :label="item.title" :name="item.name" :key="item.id">
						<el-card :body-style="siteContentViewHeight">
							<iframe v-if="item.type === 'iframe'" :src="item.iframeUrl" width="100%" height="100%"
								frameborder="0" scrolling="yes"></iframe>
								<keep-alive>
									<router-view v-if="(item.name === mainTabsActiveName) && $route.meta.KeepAlive"></router-view>
								</keep-alive>
								<router-view v-if="(item.name === mainTabsActiveName) && !$route.meta.KeepAlive"></router-view>
						</el-card>
					</el-tab-pane>
				</el-tabs>
				<!-- 主入口标签页 e -->
				<el-card v-else :body-style="siteContentViewHeight">
					<keep-alive>
						<router-view v-if="$route.meta.KeepAlive"></router-view>
					</keep-alive>
					<router-view v-if="!$route.meta.KeepAlive"></router-view>
				</el-card>
			</main>
		</div>
		<!-- 密码修改弹窗页面 -->
		<ModifyPassword v-if="modifyPWVisible" ref="modifyPW" />
	</div>
</template>

<script>
	import SvgIcon from '../components/SvgIcon.vue';
	import {
		isUrl
	} from '../utils/validate.js';
	import {
		ref,
		provide
	} from 'vue';
	import ModifyPassword from "@/views/admin/components/Modify-Password";
	export default {
		name: 'Main',
		//依赖注入方式控制loading
		provide() {
			return {
				main: this
			}
		},
		components: {
			ModifyPassword,
			SvgIcon
		},
		data() {
			return {
				iconView: {
					0: '',
					1: ''
				},
				loading: false, //全局加载等待...
				navbarLayoutType: '',
				sidebarFold: false,
				avatar: '',
				deptName: '',
				username: '',
				realName: '',
				menus: [],
				documentClientHeight: 0,
				siteContentViewHeight: {},
				height: null,
				mainTabs: [],
				menuActiveName: '',
				sidebarLayoutSkin: 'red',
				modifyPWVisible: false,
			}
		},
		created() {
			let that = this;
			that.routeHandle(that.$route);
			/**
     that.$options.sockets.onopen = function(resp) {
				//发送心跳检测，避免超时后服务端切断连接
				setInterval(function() {
					that.$socket.sendObj({
						opt: 'ping'
					}, 60*1000)
				})
			}
     */
			that.username = sessionStorage.getItem('username');
			that.realName = sessionStorage.getItem('realName')
			that.deptName = sessionStorage.getItem('deptName')
		},
		watch: {
			$route: {
				handler(to, from) {
					if (to.path != from.path) {
						this.routeHandle(to);
					}
				}
			}
		},
		mounted() {
			let that = this;
			this.getMenus();
			that.resetDocumentClientHeight();
			that.loadSiteContentViewHeight();
		},
		methods: {
			showLoading() {
				this.loading = true
			},
			hideLoading() {
				this.loading = false
			},
			handleSwitch() {
				// if (this.sidebarFold) {
				// 	this.navbarLayoutType = '';
				// } else {
				// 	this.navbarLayoutType = 'fold';
				// }
				this.sidebarFold = !this.sidebarFold;
			},
			loadSiteContentViewHeight() {
				let height = this.documentClientHeight - 50 - 30 - 2;
				if (this.$route.meta.isTab) {
					height -= 40;
					this.siteContentViewHeight = isUrl(this.$route.meta.iframeUrl) ? {
						height: height + 'px'
					} : {
						minHeight: height + 'px'
					};
					this.height = provide('height', {
						height: height - 40 + 'px'
					});
				}
				this.siteContentViewHeight = {
					minHeight: height + 'px'
				};
			},
			resetDocumentClientHeight() {
				this.documentClientHeight = document.documentElement['clientHeight'];
				window.onresize = () => {
					this.documentClientHeight = document.documentElement['clientHeight'];
					this.loadSiteContentViewHeight();
				}
			},
			routeHandle(route) {
				//每次切换页面，重新计算页面高度和内容高度
				this.resetDocumentClientHeight()
				this.loadSiteContentViewHeight()
				if (route.meta.isTab) {
					//tab选中的，不存在先添加
					let tab = this.mainTabs.filter(item => item.name === route.name)[0];
					if (!tab) {
						if (route.meta.isDynamic) {
							route = this.dynamicMenuRoutes.filter(item => item.name === route.name)[0];
							if (!route) {
								return console.error('未能找到可用标签页!');
							}
						}
						tab = {
							menuId: route.meta.menuId || route.name,
							name: route.name,
							path: route.path,
							title: route.meta.title,
							type: isUrl(route.meta.iframeUrl) ? 'iframe' : 'module',
							iframeUrl: route.meta.iframeUrl || '',
							params: route.params,
							query: route.query
						};
						this.mainTabs = this.mainTabs.concat(tab);
					}
					this.menuActiveName = tab.menuId + '';
					this.mainTabsActiveName = tab.name;
				}
			},
			selectedTabHandle: function(tab, e) {
				tab = this.mainTabs.filter(item => item.name === tab.paneName);
				if (tab.length >= 1) {
					this.$router.push({
						name: tab[0].name,
						query: tab[0].query,
						params: tab[0].params
					});
				}
				// if (tab.length >= 1) {
				// 	if(tab[0].name === 'TagsCertInput') {
				// 		// this.$route.meta.skipRefresh = true
				// 		this.$router.replace({
				// 			//name: tab[0].name,
				// 			path: tab[0].path,
				// 			query: {
				// 				skipRefresh: true,
				// 				date:new Date().getTime()
				// 			},
				// 			params: tab[0].params
				// 		}).catch(err=>{
				// 			if (err.name !== 'NavigationDuplicated') {
				// 			    // 处理实际的错误
				// 			  }
				// 		});
				// 	} else {
				// 		this.$router.push({
				// 			name: tab[0].name,
				// 			query: {
				// 				skipRefresh: false,
				// 				date:new Date().getTime()
				// 			},
				// 			params: tab[0].params
				// 		}).catch(err=>{
				// 			if (err.name !== 'NavigationDuplicated') {
				// 			    // 处理实际的错误
				// 			  }
				// 		});
				// 	}
				// }
			},
			removeTabHandle: function(tabName) {
				this.mainTabs = this.mainTabs.filter(item => item.name !== tabName);
				if (this.mainTabs.length >= 1) {
					// 当前选中tab被删除
					if (tabName === this.mainTabsActiveName) {
						var tab = this.mainTabs[this.mainTabs.length - 1];
						this.$router.push({
							name: tab.name,
							query: tab.query,
							params: tab.params
						}, () => {
							this.mainTabsActiveName = this.$route.name;
						});
					}
				} else {
					this.menuActiveName = '';
					this.$router.push({
						name: 'Main'
					});
				}
			},
			// tabs, 关闭当前
			tabsCloseCurrentHandle: function() {
				this.removeTabHandle(this.mainTabsActiveName);
			},
			// tabs, 关闭其它
			tabsCloseOtherHandle: function() {
				this.mainTabs = this.mainTabs.filter(item => item.name === this.mainTabsActiveName);
			},
			// tabs, 关闭全部
			tabsCloseAllHandle: function() {
				this.mainTabs = [];
				this.menuActiveName = '';
				this.$router.push({
					name: 'Main'
				});
			},
			getMenus() {
				let that = this;
				that.axios.get('/hr-api/home/menus', null, {}).then(res => {
					if (res.data.code == 0) {
						console.log(res);
						that.menus = res.data.data.menus;
					} else {
						that.$message.warning(res.data.msg);
					}
				}).catch(err => {
					that.$message({
						message: err,
						type: "error",
						duration: 2000
					})
				});
			},
			modifyPasswordHandle() {
				this.modifyPWVisible = true
				this.$nextTick(() => {
					this.$refs.modifyPW.init();
				})
			},
			logout() {
				this.$confirm('是否确认退出登陆？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let that = this;
					that.axios.get('/hr-api/sys/logout').then(res => {
						if (res.data.code == 0) {
							sessionStorage.removeItem('perms')
							sessionStorage.removeItem('roles')
							sessionStorage.removeItem('token')
							sessionStorage.removeItem('username')
							sessionStorage.removeItem('realName')
							sessionStorage.removeItem('deptName')
						} else {
							that.message.error(res.data.msg)
							that.$router.replace('/login')
						}
						that.$router.push('/login');
					}).catch(err => {
						that.$router.push('/login');
						that.message.error('异常退出系统...')
					})
				}).catch(err => {
					that.$router.push('/login');
				});

			}
		}
	}
</script>
<style lang="scss">
	@import '../assets/scss/index.scss';
</style>