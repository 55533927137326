<template>
  <div id="app">
	  <keep-alive>
		  <router-view v-if="$route.meta.KeepAlive"></router-view>
	  </keep-alive>
      <router-view v-if="!$route.meta.KeepAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
</style>
