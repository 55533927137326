<template>
	<div>
		<div class="mb-2">
			<el-button type="primary" plain @click="openDialog('添加用户')" v-perms="'sys:user:add'">新 增</el-button>
		</div>
		<div class="user-table">
			<el-table
				:data="tableDataList.filter(data => !search || data.username.toLowerCase().includes(search.toLowerCase()))"
				style="width: 100%" stripe border>
				<el-table-column show-overflow-tooltip align="left" label="ID" prop="id">
				</el-table-column>
				<el-table-column label="用户名" prop="username" width="120px">
				</el-table-column>
				<el-table-column v-if="false" label="昵称" prop="nickName">
				</el-table-column>
				<el-table-column label="真实姓名" prop="realName" width="120px">
				</el-table-column>
				<el-table-column label="电话" prop="phone" width="120px">
				</el-table-column>
				<el-table-column label="所属部门" prop="deptName" width="300px">
				</el-table-column>
				<el-table-column label="性别" prop="sex">
					<template #default="scope">
						{{ scope.row.sex == '1' ? '男' : '女' }}
					</template>
				</el-table-column>
				<el-table-column label="是否被锁" prop="status">
					<template #default="scope">
						<el-switch v-model="scope.row.status == 1 ? true : false"
							:active-color="scope.row.status == 1 ?'#13ce66':'#ff4949'"
							:inactive-color="scope.row.status == 0 ?'#ff4949':'#13ce66'">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="创建时间" prop="createTime" width="200px">
				</el-table-column>
				<el-table-column label="更新时间" prop="updateTime" width="200px">
				</el-table-column>
				<el-table-column width="280px" fixed="right" align="center">
					<template #header>
						<el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
					</template>
					<template #default="scope">
						<el-button type="primary" icon="el-icon-edit" plain size="mini"
							@click="openDialog('修改用户',scope.row)"  v-perms="'sys:user:edit'"></el-button>
						<el-button type="danger" icon="el-icon-delete" size="mini" @click="delHandle(scope.row)"
							:disabled="scope.row.username==='admin'?true:false"  v-perms="'sys:user:del'"></el-button>
						<el-button type="warning" plain size="mini" @click="openDrawer(scope.row)"
							:disabled="scope.row.username==='admin'?true:false"
                       v-perms="'sys:user:role'">赋予角色</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="block">
			<el-pagination :current-page="page.currentPage" :page-size="page.pageSize" :page-sizes="page.pageSizes"
				:total="page.total" @size-change="handleSizeChange" layout="total, sizes, prev, pager, next, jumper"
				@current-change="handleCurrentChange" />
		</div>
		<!-- 新增/修改 dialog 弹出层-->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="70%" top="15vh" append-to-body 
		 :close-on-click-modal="false">
			<el-form :model="form" class="demo-form-inline" label-width="80px">
				<el-form-item label="id" v-if="false">
					<el-input v-model="form.id" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="用户名">
					<el-input v-model="form.username" placeholder="请输入" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="密码" v-if="form.id?false:true">
					<el-input v-model="form.password" type="password" placeholder="请输入" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="真实姓名">
					<el-input v-model="form.realName" placeholder="请输入" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="联系方式">
					<el-input v-model="form.phone" placeholder="请输入" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="性别">
					<el-select v-model="form.sex" placeholder="请选择" v-if="sexList">
						<el-option v-for="sex in sexList" :label="sex.name" :value="sex.value"
							:key="sex.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属部门">
					<el-input v-model="form.deptId" v-show="false"></el-input>
					<el-select ref="selectTree" v-model="form.deptName" style="width: 300px" placeholder="请选择">
						<el-option :value="form.deptName" :label="form.deptName" style="height: auto">
							<el-tree :data="treeData" :props="treeProps" :default-expand-all="true"
								:render-after-expand="true" :expand-on-click-node="true"
								@node-click="handleNodeClick" />
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否启用">
					<el-radio v-model="form.status" :label="1">启用</el-radio>
					<el-radio v-model="form.status" :label="0">禁用</el-radio>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible=false" type="primary" plain>关 闭</el-button>
					<el-button type="primary" @click="onSubmit()">确 定</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 赋予角色Drawer抽屉 -->
		<div>
			<el-drawer title="赋予角色" :visible.sync="drawerVisible" direction="rtl" size="25%">
				<div style="position: absolute;top: 80px;left: 0;right: 0;bottom: 0;">
					<div style="position: absolute;top: 0;bottom: 60px;left: 20px;right: 0;overflow-y: auto;">
						<el-checkbox-group @change="handleCheckChange" v-model="chkedRoleIds" :min="0" :max="1">
							<div v-for="item in chkRoleList" :key="item.id" class="mb-2">
								<el-checkbox :label="item.id" :key="item.id" border>
									{{item.name}}
								</el-checkbox>
							</div>
						</el-checkbox-group>
					</div>
					<div style="position: absolute;height: 60px;bottom: 0;left: 20px;"
						class="d-flex align-items-center">
						<el-button @click="drawerVisible = false" class="mr-3">取 消</el-button>
						<el-button type="primary" @click="submitSaveRole()">保 存</el-button>
					</div>
				</div>
			</el-drawer>
		</div>
	</div>
</template>

<script>
	import mixins from '@/common/mixins.js'
	export default {
    inject: ['main'],
		mixins: [mixins],
		name: 'User',
		data() {
			return {
				url: '/hr-api/sys/user/list',
				addUrl: '/hr-api/sys/user/add',
				editUrl: '/hr-api/sys/user/edit',
				delUrl: '/hr-api/sys/user/del/',
				tableDataList: [],
				title: '',
				dialogVisible: false,
				form: {}, //dialog表单对象
				treeData: [{ //所属部门下拉框
					id: 0,
					deptName: 'GRC',
					children: []
				}],
				treeProps: {
					value: 'id',
					children: 'children',
					label: 'deptName',
				},
				sexList: [{
					value: 1,
					name: '男'
				}, {
					value: 2,
					name: '女'
				}],
				//赋予角色
				drawerVisible: false,
				chkedRoleIds: [],
				chkRoleList: [],
				formRole: {
					userId: '',
					roleCheckedIds: []
				},

			}
		},
		mounted() {
			this.getDeptTree();
		},
		methods: {
			getDeptTree() {
				let that = this;
				that.axios.get('/hr-api/sys/dept/allTree', {}).then(res => {
					if (res.data.code == 0) {
						that.treeData[0].children = res.data.data;
					} else {
						that.$message.warning(res.data.msg)
					}
				}).catch(err => {
					that.$message.error(err.data.msg);
				})
			},
			//点击下拉所属部门树后触发
			handleNodeClick(node) {
				console.log(node);
				this.form.deptId = node.id;
				this.form.deptName = node.deptName;
				this.$refs.selectTree.blur();
			},
			openDialog(title, item = false) {
				let that = this;
				that.title = title
				if (!item) { //add
					that.form = {
						username: '',
						password: '',
						realName: '',
						deptId: '',
						deptName: '',
						sex: '',
						phone: '',
						status: 1
					}
				} else { //edit
					debugger;
					that.form = {
						id: item.id,
						username: item.username,
						//password: item.password,
						realName: item.realName,
						deptId: item.deptId,
						deptName: item.deptName,
						sex: item.sex,
						phone: item.phone,
						status: item.status
					}
				}
				that.dialogVisible = true
			},
			onSubmit() { //Dialog提交
				let that = this;
				if (that.form.id === '' || that.form.id === undefined || that.form.id === null) {
					that.addHandle(that.form);
				} else {
					that.editHandle(that.form);
				}
				that.dialogVisible = false;
			},
			//赋予角色
			openDrawer(item) {
				let that = this
				//1、查找所有的角色信息
				that.axios.get("/hr-api/sys/role/listAll").then(res => {
					if (res.data.code === 0) {
						that.formRole.userId = item.id
						that.chkRoleList = res.data.data
						that.chkedRoleIds = item.roleCheckedIds === null ? [] : item.roleCheckedIds //上一次已配置的角色
						that.formRole.roleCheckedIds = that.chkedRoleIds
						that.drawerVisible = true
					} else {
						that.$message.warning(res.data.msg)
					}
				}).catch(err => {
					that.$message.error(err.data.msg)
				})
			},
			handleCheckChange() {
				this.formRole.roleCheckedIds = this.chkedRoleIds;
			},
			//保存用户角色数据
			submitSaveRole() {
				let that = this
				that.axios.post('/hr-api/sys/userRole/saveRole', that.formRole, {
					token: true
				}).then(res => {
					if (res.data.code === 0) {
						that.drawerVisible = false;
						that.getTableDataList();
						that.$message.success("角色授予成功");
					} else {
						that.$message.error(res.data.msg);
					}
				}).catch(err => {
					that.$message.error(err.data.msg);
				})
			},


		}
	}
</script>

<style>
</style>
