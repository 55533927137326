<template>
  <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
	  :close-on-click-modal="false"
      width="30%">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
      <el-form-item label="原始密码" >
        <el-input type="password" v-model="dataForm.password" size="medium" clearable />
      </el-form-item>
      <el-form-item label="新 密 码" prop="newPassword">
        <el-input type="password" v-model="dataForm.newPassword" size="medium" clearable placeholder="4-10位长度" />
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input type="password" v-model="dataForm.confirmPassword" size="medium" clearable placeholder="4-10位长度"/>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="onsubmit()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "Modify-Password",
  data() {
    const validateConfirmPassword = (rule, value, callback) => {
      if(value != this.dataForm.newPassword) {
        callback(new Error("两次输入的密码不一致!"))
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      dataForm: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      dataRule: {
        // password: [{
        //   require: true, pattern: '^[a-zA-Z0-9]{5,20}$', message: '密码格式错误'
        // }],
        newPassword: [{
          //require: true, pattern: '^[a-zA-Z0-9]{6,20}$', message: '密码格式错误'
		  require: true, pattern: '^[a-zA-Z0-9]{4,10}$', message: '密码格式错误'
        }],
        confirmPassword: [
          {require: true, pattern: '^[a-zA-Z0-9]{4,10}$', message: '密码格式错误'},
          {validator: validateConfirmPassword, trigger: 'blur'}
        ]
      },

    }
  },
  methods: {
    init() {
      this.dialogVisible = true
      //因为清空表单控件是异步的，所以把清空表单控件放在下次DOM更新循环中
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      })
    },
    onsubmit() {
      let that = this
      that.$refs['dataForm'].validate(valid => {
        if(valid) {
          that.axios.post('/hr-api/sys/user/modifyPW', that.dataForm).then(res=>{
            debugger;
            if(res.data.code == 0) {
              that.$message.success('密码修改成功')
            } else {
              that.$message({
                type: "warning",
                message: res.data.msg,
                duration: 2000
              })
            }
            that.dialogVisible = false
          }).catch(err=>{
            that.$message({
              type: "error",
              message: err,
              duration: 2000
            });
          })
        }
      })
    },



  }
}
</script>

<style scoped>

</style>
