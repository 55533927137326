export default {
  state: {
    token: '',
    userId: '',
    username: ''
  },
  getters: {},
  mutations: {
    //登陆-login
    login: (state, user) => {
      //保存登陆成功后状态
      state.token = user.token;
      state.userId = user.userId;
      state.username = user.username;
	  state.realName = user.realName;
      //存储到本地
      //window.sessionStorage.setItem('user', JSON.stringify(state.user));
      window.sessionStorage.setItem('token', user.token);
      window.sessionStorage.setItem('username', user.username);
      window.sessionStorage.setItem('realName', user.realName);
    },
    //退出登陆
    logout(state) {
      //清除状态
      state.user = {};
      state.token = '';
      state.userId = '';
      state.username = '';
      window.sessionStorage.clear();
    },
  },
  actions: {}



}
