<template>
  <div class="home">
	  <!-- <div>
		  <el-row>
			  <el-col :span="6">
				  <el-calendar v-model="value">
					  <template #dateCell="{date,data}">
						  <div class="text-center">
							 {{ data.day.split("-").slice(1).join("-") }}
						  </div>
						  <div class="mt-3 text-center">
							  <span class="font-italic font-weight-bolder" style="color:chocolate">{{ getCertCountByDate(date) }}</span>
						  </div>
					  </template>
				  </el-calendar>
			  </el-col>
			  <el-col></el-col>
		  </el-row>
	  </div> -->
	  <div>
		  <el-row :gutter="70" type="flex" justify="center">
		    <el-col :span="9" class="left">
		      <h2>GRC珠宝鉴定管理系统</h2>
		      <div class="desc">
		        <p>轻松工作&nbsp;&nbsp;高效办公</p>
		        <p>协同办公&nbsp;&nbsp;让工作更简单</p>
		      </div>
		      <div class="bottom">
		        <div class="remark-container">
		          <div class="ball blue">1</div>
		          <div class="remark">先进的技术</div>
		        </div>
		        <div class="remark-container">
		          <div class="ball red">2</div>
		          <div class="remark">强大的团队</div>
		        </div>
		        <div class="remark-container">
		          <div class="ball green">3</div>
		          <div class="remark">丰富的经验</div>
		        </div>
		      </div>
		    </el-col>
		    <el-col :span="11" class="right">
		      <img src="../assets/home/banner.png" class="banner" /></el-col>
		  </el-row>
	  </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
		value: new Date(),
		certCountData: {
			'2024-05-01': 10,
			'2024-05-02': 20
		},
    }
  },
  mounted() {
    //this.dashboardData();
  },
  methods: {
    dashboardData() {
      let that = this;
      that.axios.get('/hr-api/home/dashboard', null, {}).then(res=>{

      }).catch(err=>{

      })
    },
	getCertCountByDate(date) {
		const dateKey = this.formatDate(date);
		return this.certCountData[dateKey] || 0;
	},
	formatDate(date) {
		const year = date.getFullYear();
	    const month = date.getMonth() + 1;
	    const day = date.getDate();
	    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
	}
	
  }
}
</script>

<style lang="less" scoped="scoped">
@import url('home.less');
.date-content {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
